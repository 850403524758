import React from "react"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import Col from 'react-bootstrap/Col';
import Section from "../components/Section"
import Headline from "../components/Headline"
import VimeoEmbed from "../components/VimeoEmbed"
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button"
import { getParameterByName, loadScriptPromise } from "../services/utility";

export default class Hope extends React.Component {
  render() {
    loadScriptPromise("https://www.google.com/recaptcha/api.js?render=6Lfq5fwZAAAAAANqbQ11iS3oLwBTLmbc9vCAaFz2", "recaptcha")
      .then(() => {
        if(typeof window !== 'undefined') {
          if(typeof window.grecaptcha !== 'undefined') {
            window.grecaptcha.ready(function() {
              window.grecaptcha.execute('6Lfq5fwZAAAAAANqbQ11iS3oLwBTLmbc9vCAaFz2', {action: 'homepage'})
              .then(function(token) {
                document.getElementById('captchaResponse').value = token;
              }).catch(() => {});
            });
          }  
        }
      });
    
    var gvId = "234385393";
    let src = getParameterByName('src');
    if(src && src.toLowerCase() === 'anna') { gvId = "234385393"; }
    if(src && src.toLowerCase() === 'dustin') { gvId = "234390442"; }
    if(src && src.toLowerCase() === 'bach') { gvId = "234387255"; }
    if(src && src.toLowerCase() === 'connie') { gvId = "234388821"; }
    if(src && src.toLowerCase() === 'mary') { gvId = "234392192"; }
    if(src && src.toLowerCase() === 'weston') { gvId = undefined; }
    if(src && src.toLowerCase() === 'emcee') { gvId = "243315401"; }

    return ( 
      <Layout>
        <Seo title="Hope" />
        <Section className="white" useCol={false}>
          <Col xs={12} md={8}>
            <Headline>Hope</Headline>
            { gvId && <div className="pb-3">
              Thank you for coming to hear more about how you can have a relationship with Jesus Christ. 
              Please watch this video to see how simple it is to start this unloseable relationship with 
              your Creator God who loves you unconditionally.</div> }

            { gvId && <VimeoEmbed className="mb-3" vimeoId={gvId} useCinema={true} /> }

            <div className="pt-5 pb-4">
              If you've started that relationship with Jesus today or any other time, we're celebrating with you, as you have chosen LIFE!  
              Choosing to start this relationship with Jesus isn't the end &ndash; it's just the beginning of an exciting road ahead.  
              There will still be plenty of challenges you'll face in life, but with Jesus, you have someone who will walk with you each 
              step of the way.  We want to help you get off to the right start, so check out this special message, made for you.
            </div>

            <VimeoEmbed vimeoId="234395613" />

            <h6 className="text-center pt-3">We celebrate the decision that you just made, as it will change your life path forever.</h6>
          </Col>
        </Section>
        <Section className="secondary" useCol={false}>
          <Col xs={12} md={8}>
            <Headline>Free Gifts</Headline>
            <p>We would love to send some gifts your way, including a free Bible. We just need to know where to send them. 
            Fill out this form, and we'll get it all sent to you really soon. 
            Always remember that with Jesus, you are never alone!</p>

            <Form method="POST" action="https://getform.io/f/a55e2ec4-2b17-4f0e-8e0a-b157caf4eb7a">
              <Form.Group controlId="firstName">
                <Form.Label>First Name</Form.Label>
                <Form.Control required type="text" placeholder="First Name" name="firstName" />
              </Form.Group>
              <Form.Group controlId="lastName">
                <Form.Label>Last Name</Form.Label>
                <Form.Control required type="text" placeholder="Last Name" name="lastName" />
              </Form.Group>
              <Form.Group controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control required type="email" placeholder="Email" name="email" />
                <Form.Text>
                  We'll never share your email with anyone else.
                </Form.Text>
              </Form.Group>
              <Form.Group controlId="address">
                <Form.Label>Address</Form.Label>
                <Form.Control required type="text" placeholder="Address" name="address" />
              </Form.Group>
              <Form.Group controlId="city">
                <Form.Label>City</Form.Label>
                <Form.Control required type="text" placeholder="City" name="city" />
              </Form.Group>
              <Form.Group controlId="state">
                <Form.Label>State/Province</Form.Label>
                <Form.Control required type="text" placeholder="State/Province" name="state" />
              </Form.Group>
              <Form.Group controlId="postalCode">
                <Form.Label>Zip/Postal Code</Form.Label>
                <Form.Control required type="text" placeholder="Zip/Postal Code" name="postalCode" />
              </Form.Group>
              <Form.Group controlId="tribe">
                <Form.Label>Tribe</Form.Label>
                <Form.Control type="text" placeholder="Tribe" name="tribe" />
              </Form.Group>
              <Form.Group controlId="message">
                <Form.Label>Prayer Requests</Form.Label>
                <Form.Control as="textarea" rows={3} placeholder="Prayer Requests" name="message" />
              </Form.Group>
              <input type="hidden" id="captchaResponse" name="g-recaptcha-response" />
              <Button variant="primary" type="submit" className="mt-2 mb-4">
                <b>Submit</b>
              </Button>
            </Form>
          </Col>
        </Section>
      </Layout>
    );
  }
}
